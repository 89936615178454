import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="logo-suma-habitos.jpg" className="App-logo" alt="logo" />
        <a className="en-construccion">
          En Construcción
        </a>
        <p>
          Seguime en Instagram
        </p>
        <a
          className="Instagram-Link"
          href="https://www.instagram.com/suma_habitos_saludables/"
          target="_blank"
          rel="noopener noreferrer" 
        >
          <img src="Instagram_Glyph_Gradient copy.png" className="Instagram-logo"></img>
        </a>
        <img src="qrcode-instagram.png" className="qr-code" alt="QR" />
        
      </header>
    </div>
  );
}

export default App;
